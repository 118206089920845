.objectviewer {
  position: relative;

  display: grid;
  grid-template-columns: auto 1fr;

  background-color: var(--bg);
  border: 1px solid var(--registration);
  border-radius: 4px;

  overflow: hidden;
}

.objectviewer-disabled--true {
  opacity: 0.5;
}

.objectviewer > .objectviewer-label {
  position: absolute;
  top: 12px;
  left: 12px;
}

.objectviewer > .objectviewer-field,
.objectviewer > .objectviewer-value {
  position: relative;
  padding: 24px;
  border: 1px solid var(--registration);
  margin: -1px;
  margin-bottom: 0;
}
.objectviewer > .objectviewer-value {
  border-left-width: 0px;
}

.objectviewer > .objectviewer-field:nth-last-child(2),
.objectviewer > .objectviewer-value:last-child {
  margin-bottom: -1px;
}
