.usereditor {
  padding: var(--padding);
}

.usereditor button,
.usereditor .input,
.usereditor input {
  width: 100%;
}

.usereditor .usereditor-buttons {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: var(--gap);
}
