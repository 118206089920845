.input {
  position: relative;
  display: inline-block;
}
.input:hover {
  cursor: text;
}

.input .input-label {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);

  transition: var(--transition-duration-fast) var(--transition-easing);
}
.input .input-label p {
  transition: var(--transition-duration-fast) var(--transition-easing);
}
.input.input-active--true .input-label {
  top: 12px;
  left: 12px;
  transform: none;
}

.input .input-icon {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
.input .input-icon:hover {
  cursor: pointer;
}

.input-input input {
  all: unset;

  background-color: var(--bg);
  color: var(--registration);

  padding: 24px;
  border-radius: 4px;
  border: 1px solid var(--registration);

  box-sizing: inherit;

  width: 100%;
}
.input-input input[disabled] {
  opacity: 0.5;
}

.input.input-withicon .icon {
  stroke: red;
}
