.roleeditor .roleeditor-schemas {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: var(--gap);

  padding: var(--padding);
}

.roleeditor button,
.roleeditor .input,
.roleeditor input {
  width: 100%;
}

.roleeditor .roleeditor-buttons {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: var(--gap);

  padding: var(--padding);
  padding-top: 0;
}
