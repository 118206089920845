.objecteditor {
  position: sticky;
  top: 0;

  display: flex;
  flex-flow: column nowrap;
  grid-gap: var(--gap);

  padding: var(--padding);
}

.objecteditor button,
.objecteditor .input,
.objecteditor input,
.objecteditor .textarea,
.objecteditor textarea {
  width: 100%;
}

.objecteditor .objecteditor-buttons {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: var(--gap);
}
