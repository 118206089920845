.icon {
  display: grid;
  place-content: center;
}

.icon-size--xxxxs {
  width: 8px;
  height: 8px;

  padding: 0.5px;
}
.icon-size--xxxs {
  width: 12px;
  height: 12px;

  padding: 1px;
}
.icon-size--xxs {
  width: 16px;
  height: 16px;

  padding: 2px;
}
.icon-size--xs {
  width: 20px;
  height: 20px;

  padding: 2px;
}
.icon-size--sm {
  width: 32px;
  height: 32px;

  padding: 4px;
}
.icon-size--md {
  width: 48px;
  height: 48px;

  padding: 6px;
}
.icon-size--lg {
  width: 64px;
  height: 64px;

  padding: 8px;
}
.icon-size--xl {
  width: 96px;
  height: 96px;

  padding: 12px;
}

.icon-img {
  width: 100%;
  height: 100%;

  fill: var(--registration);
  stroke: var(--registration);
}
