.tabgroup {
  display: grid;
}

.tabgroup-variant--vertical {
  grid-template-rows: none;
  grid-template-columns: auto 1fr;
  min-height: 100%;
  min-width: unset;
}
.tabgroup-variant--vertical.tabgroup-minimized--true {
  grid-template-columns: 2rem 1fr;
}
.tabgroup-variant--horizontal {
  grid-template-rows: auto 1fr;
  grid-template-columns: none;
  min-height: unset;
  min-width: 100%;
  max-width: 100vw;
}
.tabgroup-variant--horizontal.tabgroup-minimized--true {
  grid-template-rows: 2rem 1fr;
}

.tabgroup > .tabgroup-tabs {
  position: relative;
  padding: var(--padding);
}
.tabgroup.tabgroup-variant--vertical > .tabgroup-tabs {
  border-right: 1px solid var(--registration);
}
.tabgroup.tabgroup-variant--horizontal > .tabgroup-tabs {
  padding: 0;
  padding-top: var(--padding);
  border-bottom: 1px solid var(--registration);
  max-width: 100vw;
}
.tabgroup.tabgroup-minimized--true > .tabgroup-tabs {
  overflow: hidden;
  padding: 0;
}

.tabgroup.tabgroup-variant--horizontal > .tabgroup-tabs > * {
  margin-left: var(--padding);
}

.tabgroup > .tabgroup-tabs > .tabgroup-tabs--tablist {
  display: flex;
  grid-gap: var(--gap);
}
.tabgroup.tabgroup-variant--vertical
  > .tabgroup-tabs
  > .tabgroup-tabs--tablist {
  flex-flow: column nowrap;
}
.tabgroup.tabgroup-variant--horizontal
  > .tabgroup-tabs
  > .tabgroup-tabs--tablist {
  flex-flow: row nowrap;
  max-width: 100vw;
  margin-left: 0;
  padding: 0 var(--padding);
  overflow: auto;
}

.tabgroup > .tabgroup-tabs > .tabgroup-tabs--tabheader {
  margin-bottom: var(--gap);
}

.tabgroup > .tabgroup-tabs > .tabgroup-tabs--minimizer {
  position: absolute;
}
.tabgroup.tabgroup-minimized--true
  > .tabgroup-tabs
  > .tabgroup-tabs--minimizer {
  border-radius: 0;
  width: calc(300% + 2px);
  height: calc(100% + 2px);
}
.tabgroup.tabgroup-variant--vertical
  > .tabgroup-tabs
  > .tabgroup-tabs--minimizer {
  top: var(--padding);
  right: 0;
  transform: translateX(50%);
}
.tabgroup.tabgroup-variant--vertical.tabgroup-minimized--true
  > .tabgroup-tabs
  > .tabgroup-tabs--minimizer {
  left: unset;
  bottom: unset;
  top: -1px;
  right: -1px;
  transform: none;
}
.tabgroup.tabgroup-variant--horizontal
  > .tabgroup-tabs
  > .tabgroup-tabs--minimizer {
  margin-left: 0px;
  left: var(--half-padding);
  bottom: 0;
  transform: translateX(-50%) translateY(50%);
}
.tabgroup.tabgroup-variant--horizontal.tabgroup-minimized--true
  > .tabgroup-tabs
  > .tabgroup-tabs--minimizer {
  top: unset;
  left: unset;
  right: -1px;
  bottom: -1px;
  transform: none;
}

.tabgroup.tabgroup-variant--horizontal
  > .tabgroup-tabs
  > .tabgroup-tabs--tablist
  > .button {
  min-width: max-content;
}
.tabgroup.tabgroup-variant--horizontal
  > .tabgroup-tabs
  > .tabgroup-tabs--tablist
  > .button:not(.button-active) {
  border-color: var(--bg);
}
.tabgroup.tabgroup-variant--horizontal
  > .tabgroup-tabs
  > .tabgroup-tabs--tablist
  > .button:not(.button-active):hover {
  color: var(--registration);
  background-color: var(--bg);
}

.tabgroup.tabgroup-variant--vertical
  > .tabgroup-tabs
  > .tabgroup-tabs--tablist
  > .button.button-active {
  margin-right: calc(-1 * var(--padding));
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tabgroup.tabgroup-variant--horizontal
  > .tabgroup-tabs
  > .tabgroup-tabs--tablist
  > .button.button-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
