.button {
  all: unset;

  color: var(--registration);

  padding: 24px;
  border-radius: 4px;
  border: 1px solid var(--registration);

  box-sizing: inherit;
}
.button[disabled] {
  opacity: 0.5;
}
.button:not([disabled]):hover,
.button.button-active {
  cursor: pointer;
  background-color: var(--registration);
  color: var(--bg);
}
.button:not([disabled]):hover .icon-img,
.button.button-active .icon-img {
  fill: var(--bg);
  stroke: var(--bg);
}

button.button-size--xxxs {
  padding: 1px;
}
button.button-size--xxs {
  padding: 1px;
}
button.button-size--xs {
  padding: 2px;
}

.button.button-withicon {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
}
.button.button-variant--icon {
  background: var(--bg);
  grid-template-columns: 1fr;
  place-content: center;
}
