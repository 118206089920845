h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: var(--registration);
}

.heading-variant--h1 {
  font-size: 48px;
  font-weight: 900;
  margin: 1.5rem 0;
}
.heading-variant--h2 {
  font-size: 40px;
  font-weight: 800;
  margin: 1.25rem 0;
}
.heading-variant--h3 {
  font-size: 32px;
  font-weight: 700;
  margin: 1.25rem 0;
}
.heading-variant--h4 {
  font-size: 28px;
  font-weight: 600;
  margin: 1rem 0;
}
.heading-variant--h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 1rem 0;
}
.heading-variant--h6 {
  font-size: 20px;
  font-weight: 600;
  margin: 1rem 0;
}
.heading-variant--logo {
  font-family: "Lexend Zetta", sans-serif;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  margin: 1rem 0;
}
