.dropdown {
  position: relative;
}

.dropdown-trigger {
  position: relative;
}
.dropdown-trigger:not(.dropdown-trigger-disabled--true):hover {
  cursor: pointer;
}

.dropdown-trigger--label {
  position: absolute;
  top: 12px;
  left: 12px;
}
.dropdown:not(.dropdown-variant--text)
  .dropdown-trigger:not(.dropdown-trigger-disabled--true):hover
  .dropdown-trigger--label
  p {
  color: var(--bg);
}
.dropdown-trigger.dropdown-trigger-disabled--true .dropdown-trigger--label p {
  opacity: 0.5;
}

.dropdown-dropdown {
  position: absolute;
  top: calc(100% - 1px);

  z-index: 999;
}

.dropdown-dropdown.dropdown-dropdown-visible--true {
  opacity: 1;
}
.dropdown-dropdown.dropdown-dropdown-visible--false {
  opacity: 0;
  pointer-events: none;
}
