*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --dark: #222222;
  --light: #efefef;
  --red: #ff5643;
  --registration: var(--light);
  --bg: var(--dark);
  --error: var(--red);
  background-color: var(--bg);
  height: 100%;

  --transition-easing: cubic-bezier(0.9, 0, 0.1, 0);
  --transition-duration-regular: 0.15s;
  --transition-duration-fast: 0.075s;
  --transition-duration-slow: 0.3s;

  --padding: 64px;
  --half-padding: calc(var(--padding) / 2);
  --gap: 24px;
}
@media (max-width: 1000px) {
  :root {
    --padding: 32px;
    --gap: 24px;
  }
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.color--dark {
  --registration: var(--dark);
  --bg: var(--light);
}
.color--light {
  --registration: var(--light);
  --bg: var(--dark);
}

.font-family--monospace {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
