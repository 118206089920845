.objecteditor {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 24px;

  padding: 64px;
}

.objecteditor button,
.objecteditor .input,
.objecteditor input {
  width: 100%;
}

.objecteditor .objecteditor-buttons {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 24px;
}
