.array {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  background-color: var(--bg);
  border: 1px solid var(--registration);
  border-radius: 4px;

  overflow-x: hidden;
  overflow-y: auto;

  max-height: calc(3 * 2 * var(--padding) + 3rem);
  margin-bottom: -1px;
}

.array-disabled--true {
  opacity: 0.5;
}

.array > .array-label {
  position: absolute;
  top: 12px;
  left: 12px;
}

.array > .array-element {
  position: relative;
  padding: 24px;
  border: 1px solid var(--registration);
  margin: -1px;
  margin-bottom: 0;
}
.array > .array-element--input {
  margin: -1px;
  margin-bottom: 0;
}
.array > .array-element--input > .input .input-input input {
  border-radius: 0;
}
.array > div:last-child {
  clip-path: inset(1px 0);
}

.array.array-withicon > .array-element > .array-element--icon {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

.array.array-interactive--true > .array-element:hover {
  background-color: var(--registration);
  cursor: pointer;
}
.array.array-interactive--true > .array-element:hover > p {
  color: var(--bg);
}
.array.array-interactive--true
  > .array-element:hover
  > .array-element--icon
  .icon-img {
  stroke: var(--bg);
}
