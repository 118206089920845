.login {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  padding: var(--padding);
}

.login-form {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: var(--gap);
  padding: var(--padding);
  border: 1px solid var(--registration);
  border-radius: 4px;
}
