.text {
  color: var(--registration);
}

.text-variant--label {
  font-size: 8px;
  margin: 0;
  user-select: none;
}
.text-variant--strong {
  font-weight: bold;
}
.text-variant--error {
  font-size: 12px;
  color: var(--error);
  margin: 0;
  user-select: none;
}
