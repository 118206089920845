.textarea {
  position: relative;
  display: inline-block;

  min-height: calc(48px + 1rem);
}
.textarea:hover {
  cursor: text;
}

.textarea .textarea-label {
  position: absolute;
  top: calc(24px + 0.5em);
  left: 24px;
  transform: translateY(-50%);

  transition: var(--transition-duration-fast) var(--transition-easing);
}
.textarea .textarea-label p {
  transition: var(--transition-duration-fast) var(--transition-easing);
}
.textarea.textarea-active--true .textarea-label {
  top: 12px;
  left: 12px;
  transform: none;
}

.textarea-textarea textarea {
  all: unset;

  position: absolute;
  top: 0;

  background-color: var(--bg);
  color: var(--registration);

  padding: 24px;
  border-radius: 4px;
  border: 1px solid var(--registration);

  box-sizing: inherit;

  width: 100%;
  height: 100%;

  overflow: hidden;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
}
.textarea-textarea textarea[disabled] {
  opacity: 0.5;
}

.textarea-hidden {
  padding: 24px;
  border-radius: 4px;
  border: 1px solid var(--registration);

  opacity: 0;
  pointer-events: none;

  width: 100%;
  height: auto;

  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
}
